import { render, staticRenderFns } from "./SettingCurationM.vue?vue&type=template&id=28eacd03&scoped=true"
import script from "./SettingCurationM.vue?vue&type=script&lang=js"
export * from "./SettingCurationM.vue?vue&type=script&lang=js"
import style0 from "@/assets/css/player/settingcurationmobile.css?vue&type=style&index=0&id=28eacd03&prod&scoped=true&lang=css&external"
import style1 from "@/assets/css/modal/space/playstop.css?vue&type=style&index=1&id=28eacd03&prod&scoped=true&lang=css&external"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "28eacd03",
  null
  
)

export default component.exports