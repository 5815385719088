<template>
  <div class="curation_setting_modal">
    <!-- 일관된 장르 아닌경우 팝업 -->
    <CommonConfirm
      v-if="isGenreComp"
      :confirmid="'genreComp'"
      @close-modal="onCloseGenreModal"
      @confirm-modal="onConfirmGenreModal"
    >
      <template #contents>
        <div class="stopmodal_body">
          <h4 class="stopmodal_text1" v-html="'선택하신 장르로 큐레이션할 경우'"></h4>
          <h4 class="stopmodal_text2" v-html="'분위기가 일관되지 않을 수 있습니다.'"></h4>
          <h4 class="stopmodal_text3" v-html="'선택하신 장르로 계속 하시겠습니까?'"></h4>
        </div>
      </template>
    </CommonConfirm>
    <vocal-modalmobile
      v-if="isShowVocalModal"
      :generobject="genreObject"
      @close-vocalmodal="onCloseVocalmodal"
      @confirm-vocal="onConfirmVocal"
    ></vocal-modalmobile>
    <alert-modal
      v-if="isOverGenreModal"
      :alertText="'선호장르는 최대 3개까지 선택가능합니다.'"
      @closeModal="onCloseOverGenreModal"
    />
    <!-- ################## 듀토리얼 Dim ################## -->
    <div class="curation_setting_dim" v-if="!timeCurationStep1">
      <div class="mo_tab_main">
        <div class="mo_curation_setting_top_wrap">
          <div class="mo_curation_setting_top dim dp_flex flex_end align_center">
            <img
              class="curation_setting_header_icon timeicon"
              src="/media/img/curation/acute.png"
              alt="icon_close_24"
            />
          </div>
        </div>
      </div>
      <div class="mo_tour_box">
        <p class="tour_desc">이제, 시간별로도 큐레이션을 설정할 수 있어요!</p>
        <a class="cursor mo_tour_box_next_btn" @click="onClickTutorial">다음</a>
      </div>
    </div>
    <!-- ################## 듀토리얼 Dim ################## -->
    <header class="curation_setting_modal_header">
      <img
        class="curation_setting_header_icon"
        @click="$emit('close-curation')"
        src="/media/img/curation/arrow_forward_white.png"
        alt="icon_close_24"
      />

      <h3 class="curation_setting_header_title">큐레이션 설정</h3>
      <img
        class="curation_setting_header_icon timeicon"
        @click="onClickTimeSetting"
        src="/media/img/curation/acute.png"
        alt="icon_close_24"
      />
    </header>
    <main class="mo_tab_main">
      <!--################ Store ################-->
      <div class="mo_tab tab1" v-if="curation.spaceType === 'Store'">
        <div class="mo_preferred_country">
          <label class="mo_tab_label"
            >국내곡 비율
            <span v-if="alertCountry" class="warning">국내곡 비율은 최대 80%까지 선택 가능합니다.</span>
          </label>
          <div class="mo_curation_percent_bar">
            <div
              class="mo_curation_percent_item half first"
              :class="{ checked: parseInt(curationSetting.domesticRate) > 0 }"
              @click="clickCountry(0)"
            >
              <input type="checkbox" id="zero" hidden value="0" />
            </div>
            <div
              class="mo_curation_percent_item"
              :class="{ checked: parseInt(curationSetting.domesticRate) > 10 }"
              @click="clickCountry(10)"
            >
              <div v-show="parseInt(curationSetting.domesticRate) === 10" class="last_checked"></div>
              <label for="ten" class="percent-dot"></label>
              <input type="checkbox" id="ten" hidden value="10" />
            </div>
            <div
              class="mo_curation_percent_item"
              :class="{ checked: parseInt(curationSetting.domesticRate) > 20 }"
              @click="clickCountry(20)"
            >
              <div v-show="parseInt(curationSetting.domesticRate) === 20" class="last_checked"></div>
              <label for="twenty" class="percent-dot"></label>
              <input type="checkbox" id="twenty" hidden value="20" />
            </div>
            <div
              class="mo_curation_percent_item"
              :class="{ checked: parseInt(curationSetting.domesticRate) > 30 }"
              @click="clickCountry(30)"
            >
              <div v-show="parseInt(curationSetting.domesticRate) === 30" class="last_checked"></div>
              <label for="thirty" class="percent-dot"></label>
              <input type="checkbox" id="thirty" hidden value="30" />
            </div>
            <div
              class="mo_curation_percent_item"
              :class="{ checked: parseInt(curationSetting.domesticRate) > 40 }"
              @click="clickCountry(40)"
            >
              <div v-show="parseInt(curationSetting.domesticRate) === 40" class="last_checked"></div>
              <label for="forty" class="percent-dot"></label>
              <input type="checkbox" id="forty" hidden value="40" />
            </div>
            <div
              class="mo_curation_percent_item"
              :class="{ checked: parseInt(curationSetting.domesticRate) > 50 }"
              @click="clickCountry(50)"
            >
              <div v-show="parseInt(curationSetting.domesticRate) === 50" class="last_checked"></div>
              <label for="fifty" class="percent-dot"></label>
              <input type="checkbox" id="fifty" hidden value="50" />
            </div>
            <div
              class="mo_curation_percent_item"
              :class="{ checked: parseInt(curationSetting.domesticRate) > 60 }"
              @click="clickCountry(60)"
            >
              <div v-show="parseInt(curationSetting.domesticRate) === 60" class="last_checked"></div>
              <label for="sixty" class="percent-dot"></label>
              <input type="checkbox" id="sixty" hidden value="60" />
            </div>
            <div
              class="mo_curation_percent_item"
              :class="{ checked: parseInt(curationSetting.domesticRate) > 70 }"
              @click="clickCountry(70)"
            >
              <div v-show="parseInt(curationSetting.domesticRate) === 70" class="last_checked"></div>
              <label for="seventy" class="percent-dot"></label>
              <input type="checkbox" id="seventy" hidden value="70" />
            </div>
            <div
              class="mo_curation_percent_item"
              :class="{ checked: parseInt(curationSetting.domesticRate) > 80 }"
              @click="clickCountry(80)"
            >
              <div v-show="parseInt(curationSetting.domesticRate) === 80" class="last_checked"></div>
              <label for="eighty" class="percent-dot"></label>
              <input type="checkbox" id="eighty" hidden value="80" />
            </div>
            <div class="mo_curation_percent_item" @click="clickCountry(90)">
              <label for="ninety" class="percent-dot"></label>
              <input type="checkbox" id="ninety" hidden value="90" />
            </div>
            <div class="mo_curation_percent_item half last" @click="clickCountry(100)">
              <input type="checkbox" id="onehundred" hidden value="100" />
            </div>
          </div>
          <div class="mo_percent_text">
            <div class="percent-box">
              국내 <label class="percent-number"> {{ curationSetting.domesticRate }}%</label>
            </div>
            <div class="percent-box">
              해외 <label class="percent-number"> {{ 100 - curationSetting.domesticRate }}%</label>
            </div>
          </div>
        </div>
        <div class="mo_list_item">
          <label class="mo_tab_label"
            >선호장르(최대 3개)
            <span v-if="alertCountryTrot" class="sub_title">해외곡만 선호하시는 경우, 트로트는 선택 불가합니다.</span>
          </label>
          <ul class="mo_curation_list">
            <li class="mo_curation_items" v-for="(item, index) in getGenre1" :key="item.id">
              <label
                class="mo_curation_items_label color"
                :class="{
                  on: getIsActive(item),
                  disabled: parseInt(curationSetting.domesticRate) === 0 && parseInt(item.value) === 10
                }"
                :for="`genre${index + 1}`"
                @click="
                  parseInt(curationSetting.domesticRate) === 0 && parseInt(item.value) === 10
                    ? () => {}
                    : clickGenre(item.value, item.koText)
                "
              >
                {{ item.koText }}

                <img
                  v-if="item.value === 5 || item.value === 11"
                  class="mo_curation_arrow"
                  :src="
                    getIsActive(item)
                      ? '/media/img/curation/arrow_forward_black.png'
                      : '/media/img/curation/arrow_forward_white.png'
                  "
                  alt="arrow_forward_black.png"
                />
              </label>
              <input type="checkbox" hidden :id="`genre${index + 1}`" :value="item.value" />
            </li>
          </ul>
        </div>
        <div class="mo_list_item">
          <label class="mo_tab_label">선호시대 </label>
          <ul class="mo_curation_list">
            <li
              class="mo_curation_items"
              :class="{ last: index === getPeroid.length - 1 }"
              v-for="(item, index) in getPeroid"
              :key="item.id"
            >
              <label
                class="mo_curation_items_label"
                :class="{ on: peroid.includes(parseInt(item.value)) }"
                :for="`peroid${index + 1}`"
                >{{ item.text }}</label
              >
              <input type="checkbox" hidden :id="`peroid${index + 1}`" v-model="peroid" :value="item.value" />
            </li>
          </ul>
        </div>
        <div class="mo_list_item">
          <label class="mo_tab_label">주요 고객층</label>
          <ul class="mo_curation_list">
            <li class="mo_curation_items" v-for="item in getTarget" :key="item.id">
              <label
                class="mo_curation_items_label"
                :class="{ on: target.includes(parseInt(item.value) || target.includes(item.value)) }"
                :for="item.id"
                >{{ item.koText }}</label
              >
              <input type="checkbox" v-model="target" hidden :id="item.id" :value="item.value" />
            </li>
          </ul>
        </div>
        <div class="mo_list_item">
          <label class="mo_tab_label">주요 인테리어 색상</label>
          <ul class="mo_curation_list">
            <li class="mo_curation_items" v-for="item in moGetColors1" :key="item.no">
              <label
                class="mo_curation_items_label color"
                :class="{ on: item.no === color }"
                :for="`color${item.no}`"
                @click="clickColor(item.no)"
              >
                <img class="mo_curation_items_colorimg" :src="item.img" :alt="item.colorNm" />
                {{ item.colorNm }}</label
              >
              <input type="radio" hidden :id="`color${item.no}`" :value="item.no" />
            </li>
          </ul>
        </div>
        <div class="mo_list_item">
          <label class="mo_tab_label">주요 인테리어 소재(최대 3개)</label>
          <ul class="mo_curation_list">
            <li class="mo_curation_items" v-for="item in moGetMaterial1" :key="`material${item.no}`">
              <label
                class="mo_curation_items_label"
                :class="{ on: material.includes(item.no) }"
                :for="`material${item.no}`"
                @click="clickMaterial(item.no)"
                >{{ item.materialNm }}</label
              >
              <input type="checkbox" hidden :id="`material${item.no}`" :value="item.no" />
            </li>
          </ul>
        </div>
      </div>
      <!--################ Car ################-->
      <div class="mo_tab tab1" v-if="selectTab === 1 && curation.spaceType === 'Car'">
        <div class="mo_list_item first">
          <label class="mo_tab_label">차량 이용목적</label>
          <ul class="mo_curation_list">
            <li class="mo_curation_items" v-for="item in carPurpose1" :key="item.id">
              <label class="mo_curation_items_label" :class="{ on: carPurpose.includes(item.value) }" :for="item.id">{{
                item.koText
              }}</label>
              <input type="checkbox" hidden :id="item.id" :value="item.value" v-model="carPurpose" />
            </li>
          </ul>
        </div>
        <div class="mo_list_item">
          <label class="mo_tab_label"
            >국내곡 비율
            <span v-if="alertCountry" class="warning">해외곡만 선호하시는 경우, 트로트는 선택 불가합니다.</span>
          </label>
          <div class="mo_curation_percent_bar">
            <div
              class="mo_curation_percent_item half first"
              :class="{ checked: parseInt(curationSetting.domesticRate) > 0 }"
              @click="clickCountry(0)"
            >
              <input type="checkbox" id="zero" hidden value="0" />
            </div>
            <div
              class="mo_curation_percent_item"
              :class="{ checked: parseInt(curationSetting.domesticRate) > 10 }"
              @click="clickCountry(10)"
            >
              <div v-show="parseInt(curationSetting.domesticRate) === 10" class="last_checked"></div>
              <label for="ten" class="percent-dot"></label>
              <input type="checkbox" id="ten" hidden value="10" />
            </div>
            <div
              class="mo_curation_percent_item"
              :class="{ checked: parseInt(curationSetting.domesticRate) > 20 }"
              @click="clickCountry(20)"
            >
              <div v-show="parseInt(curationSetting.domesticRate) === 20" class="last_checked"></div>
              <label for="twenty" class="percent-dot"></label>
              <input type="checkbox" id="twenty" hidden value="20" />
            </div>
            <div
              class="mo_curation_percent_item"
              :class="{ checked: parseInt(curationSetting.domesticRate) > 30 }"
              @click="clickCountry(30)"
            >
              <div v-show="parseInt(curationSetting.domesticRate) === 30" class="last_checked"></div>
              <label for="thirty" class="percent-dot"></label>
              <input type="checkbox" id="thirty" hidden value="30" />
            </div>
            <div
              class="mo_curation_percent_item"
              :class="{ checked: parseInt(curationSetting.domesticRate) > 40 }"
              @click="clickCountry(40)"
            >
              <div v-show="parseInt(curationSetting.domesticRate) === 40" class="last_checked"></div>
              <label for="forty" class="percent-dot"></label>
              <input type="checkbox" id="forty" hidden value="40" />
            </div>
            <div
              class="mo_curation_percent_item"
              :class="{ checked: parseInt(curationSetting.domesticRate) > 50 }"
              @click="clickCountry(50)"
            >
              <div v-show="parseInt(curationSetting.domesticRate) === 50" class="last_checked"></div>
              <label for="fifty" class="percent-dot"></label>
              <input type="checkbox" id="fifty" hidden value="50" />
            </div>
            <div
              class="mo_curation_percent_item"
              :class="{ checked: parseInt(curationSetting.domesticRate) > 60 }"
              @click="clickCountry(60)"
            >
              <div v-show="parseInt(curationSetting.domesticRate) === 60" class="last_checked"></div>
              <label for="sixty" class="percent-dot"></label>
              <input type="checkbox" id="sixty" hidden value="60" />
            </div>
            <div
              class="mo_curation_percent_item"
              :class="{ checked: parseInt(curationSetting.domesticRate) > 70 }"
              @click="clickCountry(70)"
            >
              <div v-show="parseInt(curationSetting.domesticRate) === 70" class="last_checked"></div>
              <label for="seventy" class="percent-dot"></label>
              <input type="checkbox" id="seventy" hidden value="70" />
            </div>
            <div
              class="mo_curation_percent_item"
              :class="{ checked: parseInt(curationSetting.domesticRate) > 80 }"
              @click="clickCountry(80)"
            >
              <div v-show="parseInt(curationSetting.domesticRate) === 80" class="last_checked"></div>
              <label for="eighty" class="percent-dot"></label>
              <input type="checkbox" id="eighty" hidden value="80" />
            </div>
            <div class="mo_curation_percent_item" @click="clickCountry(90)">
              <label for="ninety" class="percent-dot"></label>
              <input type="checkbox" id="ninety" hidden value="90" />
            </div>
            <div class="mo_curation_percent_item half last" @click="clickCountry(100)">
              <input type="checkbox" id="onehundred" hidden value="100" />
            </div>
          </div>
          <div class="mo_percent_text">
            <div class="percent-box">
              국내 <label class="percent-number"> {{ curationSetting.domesticRate }}%</label>
            </div>
            <div class="percent-box">
              해외 <label class="percent-number"> {{ 100 - curationSetting.domesticRate }}%</label>
            </div>
          </div>
        </div>
        <div class="mo_list_item">
          <label class="mo_tab_label"
            >선호장르(최대 3개)
            <span v-if="alertCountryTrot" class="sub_title">해외곡만 선호하시는 경우, 트로트는 선택 불가합니다.</span>
          </label>
          <ul class="mo_curation_list">
            <li class="mo_curation_items" v-for="(item, index) in getGenre1" :key="item.id">
              <label
                class="mo_curation_items_label"
                :class="{
                  on: getIsActive(item),
                  disabled: parseInt(curationSetting.domesticRate) === 0 && parseInt(item.value) === 10
                }"
                :for="`genre${index + 1}`"
                @click="
                  parseInt(curationSetting.domesticRate) === 0 && parseInt(item.value) === 10
                    ? () => {}
                    : clickGenre(item.value, item.koText)
                "
                >{{ item.koText }}</label
              >
              <input type="checkbox" hidden :id="`genre${index + 1}`" :value="item.value" />
            </li>
          </ul>
        </div>
        <div class="mo_list_item">
          <label class="mo_tab_label">선호시대 </label>
          <ul class="mo_curation_list">
            <li
              class="mo_curation_items"
              :class="{ last: index === getPeroid.length - 1 }"
              v-for="(item, index) in getPeroid"
              :key="item.id"
            >
              <label
                class="mo_curation_items_label"
                :class="{ on: peroid.includes(parseInt(item.value)) }"
                :for="`peroid${index + 1}`"
                >{{ item.text }}</label
              >
              <input type="checkbox" hidden :id="`peroid${index + 1}`" v-model="peroid" :value="item.value" />
            </li>
          </ul>
        </div>
      </div>
    </main>
    <footer class="mo_curation_bottom">
      <button class="mo_curation_setting_bottom_btn" @click="$emit('close-curation')">취소</button>
      <button class="mo_curation_setting_bottom_btn confirm" :disabled="btnDisabled" @click="validateCuration">
        적용
      </button>
    </footer>
  </div>
</template>
<script>
import { defineComponent } from '@vue/composition-api';
import { cloneDeep, isEqual, uniq } from 'lodash-es';
import {
  mySpaceInfo,
  getMoodAndGenre,
  updateMoreDetail,
  updateConfirmCuration,
  updateCurationAllTemp,
  automaticRecommOfPreferMusic
} from '@/service/api/profileApi';
import { curationValidation, isGoFavoriteMusic, checkInstrumental, retryAutomaticRecomm } from '@/utils/Utils';
import CommonConfirm from '../common/CommonConfirm.vue';
export default defineComponent({
  name: 'SettingCurationM',
  emits: ['close-curation', 'confirm-modal', 'confirm-modalcuraion'],
  components: {
    CommonConfirm,
    'vocal-modalmobile': () => import(`@/components/curation/VocalModalM.vue`),
    'alert-modal': () => import(`@/components/modal/Space/AlertModal.vue`)
  },
  props: {
    curation: {
      type: Object,
      required: false,
      default: () => {
        return {};
      }
    }
  },
  data() {
    return {
      carMood: -1,
      carPurpose: [],
      selectTab: 1,
      moreDetailYn: '',
      curationSetting: {},
      alertCountry: false,
      alertCountryTrot: false,
      color: null,
      genre: [],
      peroid: [],
      emotion: [],
      target: [],
      material: [],
      selectMode: -1,
      targetUnits: [],
      originalCurationInfo: {
        targetUnits: [],
        genre: [],
        peroid: [],
        emotion: [],
        color: null,
        material: []
      },
      genreTuneY: [],
      genreTuneN: [],
      isShowVocalModal: false,
      genreObject: null,
      spaceId: this.$cookies.get('spaceId') ? this.$cookies.get('spaceId') : '',
      timeCurationStep1: localStorage.getItem('timeCurationStep1') ? localStorage.getItem('timeCurationStep1') : false,
      isBtnDisabled: false,
      isGenreComp: false,
      tempvalue: null,
      isOverGenreModal: false
    };
  },

  async created() {
    this.curationSetting = cloneDeep(this.curation);
    const returnValue = await this.getMySpaceInfo();
    const { data } = returnValue;
    const { result } = data;
    this.moreDetailYn = result.moreDetailYn;

    this.setGenre();
    this.setPeroid();
    this.setEmotion();
    this.setGenreTune(true);
    this.setGenreTune(false);

    if (this.curationSetting.spaceType === 'Store') {
      this.setTarget();
      this.setColor();
      this.setTargetUnits();
      this.setMaterial();
    } else if (this.curationSetting.spaceType === 'Car') {
      this.setCarPurpose();
      this.setCarMood();
    }

    this.settingOriginalCuration();
  },
  mounted() {
    document.querySelector('.header-mo').style.zIndex = 0;
  },

  methods: {
    onCloseOverGenreModal() {
      this.isOverGenreModal = false;
    },
    onCloseGenreModal() {
      this.tempvalue = null;
      this.isGenreComp = false;
    },
    onConfirmGenreModal() {
      this.isGenreComp = false;
      this.pushGenre(this.tempvalue.val, this.tempvalue.koText);
      this.tempvalue = null;
    },
    onClickTimeSetting() {
      this.$router.push({ path: `/curationsettingtime/${this.spaceId}?fromUrl=PlayerView` }).catch(() => {});
    },
    onClickTutorial() {
      this.timeCurationStep1 = true;
      localStorage.setItem('timeCurationStep1', this.timeCurationStep1);
      this.$router.push({ path: `/curationsetting/${this.spaceId}?type=new` }).catch(() => {});
    },
    onCloseVocalmodal() {
      this.isShowVocalModal = false;
      this.genreObject = null;
    },
    onConfirmVocal(val) {
      if (val) {
        const { genrevalue, selectVocalValue } = val;
        if (selectVocalValue === 0) {
          this.genreTuneN.push(genrevalue);
        } else if (selectVocalValue === 1) {
          this.genreTuneY.push(genrevalue);
        } else if (selectVocalValue === 2) {
          this.genre.push(genrevalue);
        }
        this.isShowVocalModal = false;
      }
    },
    getIsActive(item) {
      const { value } = item;
      if (parseInt(value) === 5 || parseInt(value) === 11) {
        if (this.genre && this.genre.includes(parseInt(value))) {
          return true;
        }

        if (this.genreTuneN && this.genreTuneN.includes(parseInt(value))) {
          return true;
        }

        if (this.genreTuneY && this.genreTuneY.includes(parseInt(value))) {
          return true;
        }
      }
      return this.genre.includes(parseInt(value)) || this.genre.includes(value);
    },
    setGenreTune(isVocal = false) {
      if (isVocal) {
        if (this.curationSetting.genreTuneY) {
          this.curationSetting.genreTuneY.forEach(ele => {
            const find = this.$store.getters['curation/getGenreValue'](ele);
            if (find) {
              this.genreTuneY.push(parseInt(find.value));
            }
          });
        }
      } else {
        if (this.curationSetting.genreTuneN) {
          this.curationSetting.genreTuneN.forEach(ele => {
            const find = this.$store.getters['curation/getGenreValue'](ele);
            if (find) {
              this.genreTuneN.push(parseInt(find.value));
            }
          });
        }
      }
    },
    setCarPurpose() {
      this.carPurpose = this.curationSetting.carPurpose.map(item => this.$store.getters['player/getCarPurpose'](item));
    },
    setCarMood() {
      this.carMood = this.$store.getters['player/getCarMode'](this.curationSetting.carMood);
    },
    settingOriginalCuration() {
      if (this.curationSetting.spaceType === 'Store') {
        this.originalCurationInfo.targetUnits = this.targetUnits.sort((a, b) => a - b);
      }
      this.originalCurationInfo.genre = this.genre.sort((a, b) => a - b);
      this.originalCurationInfo.peroid = this.peroid.sort((a, b) => a - b);
      this.originalCurationInfo.emotion = this.emotion.sort((a, b) => a - b);
    },

    validateCuration() {
      let genre = [];
      if (this.genre.length > 0) {
        genre = [...this.genre];
      }

      if (this.genreTuneN.length > 0) {
        genre = [...genre, ...this.genreTuneN];
      }

      if (this.genreTuneY.length > 0) {
        genre = [...genre, ...this.genreTuneY];
      }
      const result = curationValidation(this.curationSetting, genre, this.peroid);

      const { isAlert, text } = result;
      if (isAlert) {
        this.$store.commit('player/setIsAlertModal', isAlert);
        this.$store.commit('player/setAlertText', text);
        return false;
      }

      if (genre.length > 3) {
        this.isOverGenreModal = true;
        return false;
      }

      // update curation
      this.updateCuration();
    },
    async updateCuration() {
      const spaceId = this.$cookies.get('spaceId');
      const resultDiff = this.diffCuration();
      if (resultDiff && this.$store.getters['player/getSpaceInfo'].moreDetailYn !== 'Y') {
        await updateMoreDetail(spaceId);
      }
      if (!resultDiff && this.moreDetailYn !== 'Y' && this.$store.getters['player/getSpaceInfo'].moreDetailYn !== 'Y') {
        this.settingCurationValue();
      }
      const carMood = this.curationSetting.spaceType === 'Car' ? parseInt(this.carMood) : null;
      const carPurpose =
        this.curationSetting.spaceType === 'Car' ? this.carPurpose.sort((a, b) => a - b).join(',') : null;
      const curationInfo = {
        spaceId,
        mood: this.curationSetting.spaceType === 'Store' ? this.selectMode : null,
        carMood,
        carPurpose,
        domesticRate: this.curationSetting.domesticRate,
        foreignRate: String(100 - parseInt(this.curationSetting.domesticRate)),
        genre: this.genre.sort((a, b) => a - b).join(','),
        period: this.peroid.sort((a, b) => a - b).join(','),
        emotion: this.emotion.sort((a, b) => a - b).join(',')
      };
      if (this.curationSetting.spaceType === 'Store') {
        curationInfo.target = this.target.sort((a, b) => a - b).join(',');
        curationInfo.targetUnit = this.targetUnits.sort((a, b) => a - b).join(',');
        curationInfo.color = this.color;
        curationInfo.material = this.material.sort((a, b) => a - b).join(',');

        this.updateStoreCuration(curationInfo);
      } else {
        try {
          const { genre, genreTuneN, genreTuneY } = curationInfo;

          let genreConcat = [];
          if (genre) {
            genreConcat = [...genre.split(',')];
          }

          if (genreTuneN) {
            genreConcat = [...genreConcat, ...genreTuneN.split(',')];
          }

          if (genreTuneY) {
            genreConcat = [...genreConcat, ...genreTuneY.split(',')];
          }
          const set = new Set(genreConcat);
          const isGoFavorite = isGoFavoriteMusic(Array.from(set).map(item => parseInt(item)));

          const { data: curationTempResult } = await updateCurationAllTemp(curationInfo);
          const { resultCd: curationTempResultCd } = curationTempResult;
          if (curationTempResultCd === '0000') {
            localStorage.setItem('spaceId', spaceId);
            if (isGoFavorite) {
              this.$emit('confirm-modal');
              return;
            }
            const { data: updateCurationData } = await updateConfirmCuration(spaceId);
            const { resultCd: updateCd } = updateCurationData;
            if (updateCd === '0000') {
              const { data: recommPreferData } = await automaticRecommOfPreferMusic(this.curationSetting.curationId);
              const { resultCd: recommPreferResultCd } = recommPreferData;
              if (recommPreferResultCd !== '0000') return false;
              this.$emit('confirm-modalcuraion', { curationInfo });
            } else {
              this.failUpdateCuration();
            }
          }
        } catch (error) {
          console.error('updateCuration error : ', error);
          this.failUpdateCuration();
        }
      }
    },

    diffCuration() {
      this.peroid = this.peroid.map(item => parseInt(item)).sort((a, b) => a - b);
      this.genre = this.genre.map(item => parseInt(item)).sort((a, b) => a - b);
      this.emotion = this.emotion.map(item => parseInt(item)).sort((a, b) => a - b);
      const peroidEqual = isEqual(this.peroid, this.originalCurationInfo.peroid);
      const genreEqual = isEqual(this.genre, this.originalCurationInfo.genre);
      const emotionEqual = isEqual(this.emotion, this.originalCurationInfo.emotion);
      if (this.curationSetting.spaceType === 'Store') {
        this.targetUnits = this.targetUnits.map(item => parseInt(item)).sort((a, b) => a - b);
        const targetUnitsEqual = isEqual(this.targetUnits, this.originalCurationInfo.targetUnits);
        if (!targetUnitsEqual || !peroidEqual || !genreEqual || !emotionEqual) {
          return true;
        }
        return false;
      } else {
        if (!peroidEqual || !genreEqual || !emotionEqual) {
          return true;
        }
        return false;
      }
    },
    settingCurationValue() {
      let array = [];
      let genreArray = [];
      let meotionArray = [];
      // 매장
      if (this.curationSetting.spaceType === 'Store') {
        this.target.forEach(val => {
          const item = this.$store.getters['player/getTargetUnitFilter'](parseInt(val));
          array = [...array, ...item];
        });
        const set = new Set(array);
        this.targetUnits = Array.from(set);
      } else if (this.curationSetting.spaceType === 'Car') {
        // 차량
        this.carPurpose.forEach(val => {
          const item = this.$store.getters['player/getCarGenreFilter'](parseInt(val));
          const emotionItem = this.$store.getters['player/getCarEmotionFilter'](parseInt(val));
          genreArray = [...genreArray, ...item];
          meotionArray = [...meotionArray, ...emotionItem];
        });
        const genreSet = new Set(genreArray);
        const emotionSet = new Set(meotionArray);
        this.genre = Array.from(genreSet).sort((a, b) => a - b);
        this.emotion = Array.from(emotionSet).sort((a, b) => a - b);
        if (this.carPurpose.length > 1) {
          const sort = this.carPurpose.sort((a, b) => a - b);
          this.carMood = this.$store.getters['player/getCarMoodFilter'](parseInt(sort[0]));
        } else {
          this.carMood = this.$store.getters['player/getCarMoodFilter'](parseInt(this.carPurpose[0]));
        }
      }
    },
    async updateStoreCuration(curationInfo) {
      const spaceId = this.$cookies.get('spaceId');
      try {
        this.isBtnDisabled = true;
        localStorage.setItem('spaceId', spaceId);
        if (this.moreDetailYn === 'Y') {
          const moodResult = await getMoodAndGenre(curationInfo.color, curationInfo.material);
          if (moodResult.data.resultCd === '0000') {
            const { result: moodRes } = moodResult.data;
            const { mood } = moodRes;
            curationInfo.mood = parseInt(mood);
          }
        }

        if (this.genreTuneN.length > 0) {
          curationInfo.genreTuneN = this.genreTuneN.join(',');
        }

        if (this.genreTuneY.length > 0) {
          curationInfo.genreTuneY = this.genreTuneY.join(',');
        }

        const { genre, genreTuneN, genreTuneY } = curationInfo;
        let genreConcat = [];
        if (genre) {
          genreConcat = [...genre.split(',')];
        }

        if (genreTuneN) {
          genreConcat = [...genreConcat, ...genreTuneN.split(',')];
        }

        if (genreTuneY) {
          genreConcat = [...genreConcat, ...genreTuneY.split(',')];
        }
        const set = new Set(genreConcat);
        const isGoFavorite = isGoFavoriteMusic(Array.from(set).map(item => parseInt(item)));

        curationInfo.genreTuneN = this.genreTuneN.sort((a, b) => a - b).join(',');
        curationInfo.genreTuneY = this.genreTuneY.sort((a, b) => a - b).join(',');
        curationInfo.curationId = this.curationSetting.curationId;

        // 선호음악
        const { data: curationTempData } = await updateCurationAllTemp(curationInfo);
        const { resultCd: curationTempResultCd } = curationTempData;
        if (curationTempResultCd === '0000') {
          if (isGoFavorite) {
            this.$emit('confirm-modal');
          } else {
            await updateConfirmCuration(this.spaceId, this.curationSetting.curationId);
            const { data: recommPreferData } = await automaticRecommOfPreferMusic(this.curationSetting.curationId);
            const { resultCd: recommPreferResultCd } = recommPreferData;
            if (recommPreferResultCd !== '0000') return false;
            this.$emit('confirm-modalcuraion', { curationInfo });
          }
        }
      } catch (error) {
        if (error.response) {
          const { url } = error.response.config;
          if (url === '/api/profile/automaticRecommOfPreferMusic') {
            console.log(error.response);
            retryAutomaticRecomm(
              0,
              () => {
                this.isBtnDisabled = false;
              },
              () => {
                this.isBtnDisabled = false;
              }
            );
          }
        }
        console.error('updateStoreCuration error : ', error);
      } finally {
        this.isBtnDisabled = false;
      }
    },
    failUpdateCuration() {
      alert('선호음악 선정에 실패하였습니다.');
    },
    isVocalGenre(val) {
      // 재즈,클래식
      const vocalList = [5, 11];
      if (vocalList.includes(parseInt(val))) {
        return true;
      }

      return false;
    },
    pushGenre(val, koText) {
      const total = this.genre.length + this.genreTuneN.length + this.genreTuneY.length;

      if (this.isVocalGenre(val)) {
        const findGenreIndex = this.genre.findIndex(item => item === parseInt(val));
        const findGenreTuneYIndex = this.genreTuneY.findIndex(item => item === parseInt(val));
        const findGenreTuneNIndex = this.genreTuneN.findIndex(item => item === parseInt(val));
        if (findGenreIndex > -1 || findGenreTuneYIndex > -1 || findGenreTuneNIndex > -1) {
          // 활성화 되어있는 경우
          if (findGenreIndex > -1) this.genre.splice(findGenreIndex, 1);
          if (findGenreTuneYIndex > -1) this.genreTuneY.splice(findGenreTuneYIndex, 1);
          if (findGenreTuneNIndex > -1) this.genreTuneN.splice(findGenreTuneNIndex, 1);
        } else {
          // 활성화 되어있지 않는 경우 - 모달띄우기
          if (total < 3) {
            this.genreObject = {
              genrename: koText,
              genrevalue: parseInt(val),
              selectvalue: -1
            };
            this.isShowVocalModal = true;
          } else {
            this.isOverGenreModal = true;
          }
        }
      } else {
        const findIndex = this.genre.findIndex(item => item === parseInt(val));

        if (findIndex > -1) {
          this.genre.splice(findIndex, 1);
        } else {
          if (total < 3) {
            this.genre.push(parseInt(val));
          } else {
            this.isOverGenreModal = true;
            return true;
          }
        }

        if (parseInt(this.curationSetting.domesticRate) === 0 && this.genre.length > 0) {
          this.alertCountryTrot = false;
        } else if (parseInt(this.curationSetting.domesticRate) === 0 && this.genre.length === 0) {
          this.alertCountryTrot = true;
        }
      }
    },
    clickGenre(val, koText) {
      const genre = uniq([...this.genre, ...this.genreTuneY, ...this.genreTuneN]);
      if (genre.length < 3) {
        if (checkInstrumental(genre, val)) {
          this.tempvalue = {
            val,
            koText
          };
          this.isGenreComp = true;
          return true;
        }
      }

      this.pushGenre(val, koText);
    },
    clickMaterial(val) {
      const findIndex = this.material.findIndex(findItem => findItem === val);
      if (findIndex > -1) {
        this.material.splice(findIndex, 1);
      } else {
        if (this.material.length < 3) {
          this.material.push(val);
        }
      }
      this.setMoodAndGenre();
    },
    clickColor(val) {
      this.color = parseInt(val);
      this.setMoodAndGenre();
    },
    setMoodAndGenre() {
      if (this.moreDetailYn !== 'Y') {
        getMoodAndGenre(this.color, this.material.sort((a, b) => a - b).join(',')).then(res => {
          const { resultCd, result } = res.data;
          if (resultCd === '0000') {
            const { mood, genre, emotion } = result;
            this.selectMode = parseInt(mood);
            this.genre = [];
            if (genre) {
              genre.forEach(item => {
                const findItem = this.$store.getters['curation/getGenreValue'](item);
                if (findItem) {
                  this.genre.push(parseInt(findItem.value));
                }
              });
            }
            if (emotion) {
              this.emotion = [];
              emotion.forEach(item => {
                const findItem = this.$store.getters['curation/getEmotionValue'](item);
                if (findItem) {
                  this.emotion.push(parseInt(findItem.value));
                }
              });
            }
          }
        });
      }
    },
    setColor() {
      if (this.curationSetting.spaceType === 'Store') {
        if (this.curationSetting.color) {
          const findColor = this.$store.getters['curation/getColorItem'](this.curationSetting.color);
          if (Object.keys(findColor).length > 0) {
            this.color = findColor.no;
          }
        }
      }
    },
    setTargetUnits() {
      this.targetUnits = this.curationSetting.targetUnit.map(item =>
        this.$store.getters['player/getCurationStoreTargetUnits'](item)
      );
    },
    setMaterial() {
      if (this.curationSetting.material) {
        this.material = this.curationSetting.material.map(
          item => this.$store.getters['curation/getMaterialValue'](item).no
        );
      }
    },
    setTarget() {
      this.curationSetting.target.forEach(item => {
        const find = this.$store.getters['curation/getTargetValue'](item);
        if (find) {
          this.target.push(parseInt(find.value));
        }
      });
    },
    clickCountry(value) {
      if (value > 80) {
        this.alertCountryTrot = false;
        this.alertCountry = true;
        return false;
      }
      this.alertCountryTrot = false;
      this.alertCountry = false;
      this.curationSetting.domesticRate = value;
      const idx = this.genre.findIndex(item => parseInt(item) === 10);
      if (parseInt(value) === 0 && idx > -1) {
        this.genre = this.genre.filter(el => el !== 10); // 트로트 장르 빼기
        this.alertCountryTrot = true;
      }

      if (parseInt(this.curationSetting.domesticRate) === 0 && this.genre.length > 0) {
        this.alertCountryTrot = false;
      } else if (parseInt(this.curationSetting.domesticRate) === 0 && this.genre.length === 0) {
        this.alertCountryTrot = true;
      }
    },
    setGenre() {
      if (this.curationSetting.genre) {
        this.genre = this.curationSetting.genre.map(item => this.$store.getters['player/getCurationStoreGenre'](item));
      } else {
        this.genre = [];
      }
    },
    setPeroid() {
      this.peroid = this.curationSetting.period.map(item => this.$store.getters['player/getCurationStorePeroid'](item));
    },
    setEmotion() {
      this.emotion = this.curationSetting.emotion.map(item =>
        this.$store.getters['player/getCurationStoreEmotion'](item)
      );
    },
    async getMySpaceInfo() {
      const spaceId = this.$cookies.get('spaceId');
      return await mySpaceInfo(spaceId);
    }
  },
  computed: {
    getTarget() {
      return this.$store.getters['curation/getTarget'];
    },
    moGetColors1() {
      return this.$store.getters['curation/getCurationColors'];
    },
    moGetColors2() {
      return this.$store.getters['curation/getCurationColors'].slice(5, 10);
    },
    moGetColors3() {
      return this.$store.getters['curation/getCurationColors'].slice(10, 15);
    },
    moGetMaterial1() {
      return this.$store.getters['curation/getCurationMaterial'];
    },
    getTargetUnit() {
      return this.$store.getters['curation/getTargetUnit'];
    },
    getGenre1() {
      const clone = cloneDeep(this.$store.getters['curation/getGenreAll']);
      // 재즈가 보컬없음,보컬있음에 있는지 확인
      if (this.genreTuneN.includes(clone[4].value)) {
        clone[4].koText = `재즈-보컬있음`;
      } else if (this.genreTuneY.includes(clone[4].value)) {
        clone[4].koText = `재즈-보컬없음`;
      } else {
        clone[4].koText = `재즈`;
      }

      // 클래식이 보컬없음,보컬있음에 있는지 확인
      if (this.genreTuneN.includes(clone[9].value)) {
        clone[9].koText = `클래식-보컬있음`;
      } else if (this.genreTuneY.includes(clone[9].value)) {
        clone[9].koText = `클래식-보컬없음`;
      } else {
        clone[9].koText = `클래식`;
      }
      return [
        clone[0],
        clone[1],
        clone[2],
        clone[3],
        clone[8],
        clone[5],
        clone[6],
        clone[10],
        clone[7],
        clone[4],
        clone[9]
      ];
    },
    getPeroid() {
      return this.$store.getters['curation/getPeroid'];
    },
    getEmotion() {
      const clone = cloneDeep(this.$store.getters['curation/getEmotion']);
      return clone;
    },
    carPurpose1() {
      return this.$store.getters['curation/getCarPurpose'];
    },
    btnDisabled() {
      const { spaceType } = this.curationSetting;
      if (!this.isBtnDisabled) {
        if (
          parseInt(this.curationSetting.domesticRate) === 0 &&
          (this.genre.includes(10) || this.genre.includes('10'))
        ) {
          return true;
        } else if (this.genre.length === 0 || this.peroid.length === 0 || this.emotion.length <= 2) {
          if (this.genreTuneN.length === 0 && this.genreTuneY.length === 0) {
            return true;
          } else {
            return false;
          }
        } else if (spaceType === 'Store') {
          if (
            this.targetUnits.length === 0 ||
            this.target.length === 0 ||
            this.color === null ||
            this.material.length < 1 ||
            this.material.length > 3
          ) {
            return true;
          }
        } else if (spaceType === 'Car') {
          if (this.carPurpose.length === 0) {
            return true;
          }
        } else {
          return false;
        }
      } else {
        return this.isBtnDisabled;
      }

      return false;
    }
  },
  beforeDestroy() {
    if (document.querySelector('.header-mo')) {
      document.querySelector('.header-mo').style.zIndex = '';
    }
  },
  destroyed() {}
});
</script>
<style scoped src="@/assets/css/player/settingcurationmobile.css"></style>
<style scoped src="@/assets/css/modal/space/playstop.css"></style>
